import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "./medical-service.module.scss"

function MedicalServiceTemplate({ data }) {
  const { markdownRemark: service } = data
  return (
    <Layout>
      <div className={styles.container}>
        <SEO title={service.frontmatter.name} />
        <div className={styles.service}>
          <h1>{service.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: service.html }} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MedicalServiceByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        name
        path
        title
      }
    }
  }
`

export default MedicalServiceTemplate
